import { useMemo, ReactNode } from "react";

import { useAppDispatch } from "Store/hooks";

import {
  add,
  remove as removeAction,
  flagForRemoval
} from "./Notifications.slice";
import {
  Notification,
  NotificationId,
  Priorities
} from "./Notifications.types";
import {
  makeNotification,
  ANIMATION_DURATION,
  SUCCESS_TIMEOUT
} from "./Notifications.utils";

const useNotification = () => {
  const dispatch = useAppDispatch();

  const remove = useMemo(
    () => (notificationId: NotificationId) =>
      new Promise<NotificationId>(fulfill => {
        dispatch(flagForRemoval(notificationId));
        window.setTimeout(() => {
          dispatch(removeAction(notificationId));
          fulfill(notificationId);
        }, ANIMATION_DURATION);
      }),
    [dispatch]
  );

  const api = useMemo(() => {
    const notifyWithPriority =
      (priority: Priorities) =>
      (
        partialNotification: Partial<Notification> | string,
        description?: ReactNode
      ) => {
        if (typeof partialNotification === "string") {
          partialNotification = {
            title: partialNotification
          };
        }

        if (description) {
          partialNotification.description = description;
        }

        // Auto-hide information and success messages. This can be overwritten
        // by the partialNotification if needed
        if (
          priority === "information" ||
          priority === "success" ||
          priority === "loading"
        ) {
          partialNotification.timeout =
            partialNotification.timeout || SUCCESS_TIMEOUT;
        }

        const notification = makeNotification({
          priority: priority,
          ...partialNotification
        });

        // Sets up a timer for auto destruction. There's one already included on
        // the radix implementation but is a bit hard to manage if we want to
        // decouple the notifications from the DOM.
        if (notification.timeout) {
          window.setTimeout(() => {
            remove(notification.id);
          }, notification.timeout - ANIMATION_DURATION);
        }

        dispatch(add(notification));

        return notification;
      };

    return {
      warning: notifyWithPriority("warning"),
      information: notifyWithPriority("information"),
      success: notifyWithPriority("success"),
      error: notifyWithPriority("error"),
      loading: notifyWithPriority("loading"),
      remove
    };
  }, [dispatch, remove]);

  return api;
};

export default useNotification;
