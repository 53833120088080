import { CSSObjectWithLabel, GroupBase, OptionProps } from "react-select";

export const controlActive = {
  backgroundColor: "var(--mode-bg-neutral-weakest)",
  borderColor: "var(--mode-border-primary-strong)",
  boxShadow: `0px 0px 2px 1px ${"var(--theme-primary-600)"}`
};

export const controlDisabled = {
  backgroundColor: "var(--mode-bg-neutral-weaker)"
};

export const control = (provided, state) => ({
  ...provided,
  fontFamily: "Open Sans",
  border: "none",
  backgroundColor: "var(--mode-bg-neutral-weaker)",
  borderRadius: `var(--input-layout-input-border-radius,
    var(--input-2px-border-radius, var(--2px))
  )`,
  minHeight: "40px",
  transitionDuration: "0.25s",
  transitionProperty: "background-color, border-color",
  ":hover": controlActive,
  ...(state.isFocused ? controlActive : {}),
  ...(state.selectProps.menuIsOpen ? controlActive : {}),
  ...(state.selectProps.isDisabled ? controlDisabled : {}),
  ...(state.selectProps["aria-invalid"]
    ? {
        borderColor: "var(--mode-border-critical-default)",
        boxShadow: `0px 0px 2px var(--theme-critical-600)`
      }
    : {})
});

export const indicatorSeparator = () => ({ display: "none" });

export const placeholder = provided => ({
  ...provided,
  color: "var(--mode-text-neutral-default-weak)",
  fontFamily: "Open Sans",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "15px",
  lineHeight: "24px"
});

export const indicatorsContainer = (provided, props) => ({
  ...provided,
  svg: {
    fill: props.selectProps.menuIsOpen
      ? "var(--mode-vector-primary-default)"
      : "var(--mode-border-neutral-default)",
    transform: props.selectProps.menuIsOpen ? "rotate(180deg)" : undefined
  }
});

export const singleValue = provided => ({
  ...provided,
  color: "var(--mode-text-neutral-default-weak)",
  fontFamily: "Open Sans",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "15px",
  lineHeight: "24px"
});

export const menu = provided => ({
  ...provided,
  border: "none",
  marginTop: "4px",
  padding: "8px",
  fontFamily: "Open Sans, sans-serif",
  borderRadius: "2px",
  backgroundColor: "var(--mode-bg-neutral-weakest)",
  boxShadow: `var(--box-shadow)
      var(--mode-shadow-default)`
});

export const menuPortal = provided => ({
  ...provided,
  zIndex: 9999,
  pointerEvents: "auto"
});

export const optionHover = {
  backgroundColor: "var(--mode-bg-primary-weakest)",
  color: "var(--mode-text-primary-default)"
};

export const optionFocus = {
  border: "1px solid var(--mode-border-primary-strong)",
  boxShadow:
    "0px 0px 2px var(--mode-shadow-default), inset 0px 0px 2px var(--mode-shadow-default)",
  borderRadius: "2px"
};

export const option = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>
>(
  provided: CSSObjectWithLabel,
  props: OptionProps<Option, IsMulti, Group>
): CSSObjectWithLabel => ({
  paddingTop: "4px",
  paddingBottom: "4px",
  paddingLeft: "16px",
  paddingRight: "8px",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "15px",
  lineHeight: "24px",
  border: "1px solid transparent",
  textAlign: "left",
  outline: "none",
  borderRadius: "4px",
  transition: "0.25s",
  cursor: props.isDisabled ? "not-allowed" : "pointer",
  color: props.isDisabled
    ? "var(--mode-bg-neutral-weakest)"
    : props.isFocused
      ? "var(--mode-text-primary-default)"
      : "var(--mode-text-neutral-default-weak)",
  ":hover": props.isDisabled ? {} : optionHover,
  ":focus": optionFocus
});

export const groupHeading = () => ({
  padding: "8px 16px 16px ",
  color: "var(--mode-text-neutral-weakest)",
  fontStyle: "normal",
  fontWeight: 600,
  fontSize: "13px",
  lineHeight: "16px"
});

export const group = () => ({
  ":not(:last-child)": {
    paddingBottom: "8px"
  }
});

export const input = provided => ({
  ...provided,
  color: "var(--mode-text-neutral-weak)"
});

export const valueContainer = provided => ({
  ...provided,
  paddingLeft: "16px",
  paddingRight: "16px"
});
