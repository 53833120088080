import React from "react";

import Critical from "ds/Icons/Critical";
import Information from "ds/Icons/Information";
import Loading from "ds/Icons/Loading";
import Low from "ds/Icons/Low";
import Normal from "ds/Icons/Normal";

import { Icon as StyledIcon } from "./Icon.styles";

export enum Variant {
  information = "information",
  warning = "warning",
  success = "success",
  loading = "loading",
  error = "error"
}

export interface Props {
  /**
   * One of the available variants, will handle the kind of icon rendered.
   * Defautls to `information`
   */
  variant?: keyof typeof Variant;
}

const getIcon = (variant: Props["variant"]) => {
  switch (variant) {
    case Variant.warning:
      return <Normal />;
    case Variant.success:
      return <Low />;
    case Variant.error:
      return <Critical />;
    case Variant.loading:
      return <Loading />;
    case Variant.information:
    default:
      return <Information />;
  }
};

const Icon = ({ variant = Variant.information }: Props) => (
  <StyledIcon>{getIcon(variant)}</StyledIcon>
);

export default Icon;
